import React from 'react';
import Page from '../components/templates/page';
import SiteWrapper from '../siteWrapper';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

const Gruppenstunden = (props) => {
    
    const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;
    const body = props.data.allFile.edges[0].node.childMarkdownRemark.html;

    console.log(body);

    return (
        <SiteWrapper>
            <Helmet>
              <title>KjG St. Barbara / Littenweiler - Gruppenstunden</title>
              <meta name="description" content="Jede Woche bieten wir für Kinder und Jugendliche Gruppenstunden an. Lerne neue Leute kennen oder treffe deine Freunde und hab eine schöne Zeit in der KjG St. Barbara in Freiburg Littenweiler." />
              <meta name="keywords" content="KjG, Littenweiler, Freiburg, St. Barbara, Sommerlager, Zeltlager, Jugendfreizeit, Kinderfreizeit" />
            </Helmet>
            <Page data={data} body={body} />
        </SiteWrapper>
    )
}

export default Gruppenstunden;

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "sites"} name: {eq: "gruppenstunden"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              untertitel
              color
              featuredImg {
                childImageSharp {
                  gatsbyImageData(width: 1920)
                }
              }
            }
            html
        }
      }
    }
  }
}`